import React from "react";
import styled from "styled-components";
import {PropTypes} from "prop-types";
import GatsbyImage from "gatsby-image";

const Holder = styled.div`
  background-color: ${props => props.theme.colours[props.colour]};
  color: ${props => props.colour === "purple" ? props.theme.colours.white : props.theme.colours.black};
  position: relative;
  /* 4rem to clear header */
  padding: 4rem 0 2rem 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;

const HeroText = styled.div`
  position: relative;
  padding: 0 2rem;

  h1 {
    margin: 0;
    position: relative;
    z-index: 2;
  }
`;

const MobileImageHolder = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }
`;

const DesktopImageHolder = styled.div`
  position: absolute;
  bottom: ${props => props.layoutBottom ? '-2rem' : '100%'};
  left: 0;
  width: 100%;
  z-index: 1;
  display: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: block;
  }
`;

function Hero({heroText, colour, desktopImage, mobileImage, layout}) {

  let layoutBottom = layout === "Locked to bottom of title";

  return (
    <Holder colour={colour.toLowerCase()} heroText={heroText}>

      <HeroText>
        {mobileImage && <MobileImageHolder>
          <GatsbyImage alt={mobileImage.alt} fluid={mobileImage.fluid} />
        </MobileImageHolder>}
        {desktopImage && <DesktopImageHolder layoutBottom={layoutBottom}>
          <GatsbyImage alt={desktopImage.alt} fluid={desktopImage.fluid} />
        </DesktopImageHolder>}
        <h1>{heroText}</h1>
      </HeroText>

    </Holder>
  );
}

Hero.propTypes = {
  colour: PropTypes.string,
  heroText: PropTypes.string.isRequired,
  desktopImage: PropTypes.object,
  mobileImage: PropTypes.object,
  layout: PropTypes.oneOf([
    "Locked to top of title",
    "Locked to bottom of title"
  ]),
};

Hero.defaultProps = {
  colour: "grey",
  layout: "Locked to top of title"
};

export default Hero;
